import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import BowFillIcon from '~assets/icons/bow-fill.svg';
import DomeEstateBg from '~assets/bg/dome-estate.svg';

export const Container = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 16px 370px;
    overflow: hidden;

    ${breakpoints.abovePhoneS} {
        padding: 0 16px 480px;
        min-height: 930px;
    }

    ${breakpoints.abovePhone} {
        padding: 120px 16px 700px;
    }

    ${breakpoints.aboveTablet} {
        padding: 120px 0 90px;
    }
`;

export const BowFilled = styled(BowFillIcon)`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    ${breakpoints.tablet} {
        left: -70%;
        top: 0;
    }

    ${breakpoints.aboveTablet} {
        left: calc(20% - 770px);
        top: 0;
    }
`;

export const Graphic = styled(DomeEstateBg)`
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: -1;

    ${breakpoints.tablet} {
        bottom: 0;
        transform: translateX(-50%);
        width: 80%;
    }

    ${breakpoints.aboveTablet} {
        top: 150px;
        left: -50px;
        width: 50%;
    }

    ${breakpoints.aboveBigDesktop} {
        right: 10%;
    }
`;

export const Content = styled.div`
    max-width: 593px;

    ${breakpoints.aboveTablet} {
        margin: 0 50px 0 50%;
    }
`;

export const UseCase = styled.div`
    margin: 40px 0;

    & > * {
        padding-left: 34px;
    }

    p {
        font-size: 14px;
    }
`;

export const UseCaseTitle = styled.h5`
    background: url('/assets/icons/check.svg') no-repeat left center;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 8px;
`;
